/*!
* @Author: Abdelghani
* @Date:   2016-11-15 10:04:21
* @Last Modified by:   b4n92uid
* @Last Modified time: 2017-12-13 13:45:24
*/

jQuery(function($) {

  /**
   * Fullpage navigation (fullpage.js)
   */

  $('.fullpage-layout').fullpage({
    menu: '#navbar-main-header ul',
    anchors: ['homepage', 'news', 'servicespart','servicescor','philately', 'avis', 'footer'],
    animateAnchor: false,
    navigation: false,
    scrollingSpeed: 600,
    sectionSelector: '.content-section',
    verticalCentered: false,
    responsiveWidth: 1199,

    afterRender: function () {
      setTimeout(function(){
        $('.main-header .navbar-wrap').addClass('bounceInRight');
        window.loading_screen.finish()
      }, 2000);
    },

    onLeave : function(index, nextIndex, direction) {
      $('.main-header').toggleClass('scrolled', nextIndex > 1);
      var gotop = $('.gotop');

      if(nextIndex > 1) {
        gotop.appear();

      } else {
        gotop.vanish();

      }
    },
  })

  $('a[href="#gotop"]').click(function(){
    $.fn.fullpage.moveTo('homepage', 0);
    return false;
  })

  setTimeout(function() {
    window.loading_screen.finish()
  }, 3000)

  /**
   * Big Slider
   */

  new Swiper('#big-slider.swiper-container', {
    direction: 'horizontal',
    loop: true,

    speed: 300,
    autoplay: {delay: 6000},

    grabCursor: true,

    keyboardControl: {
      enabled: true
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination'
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    on: {
      slideChangeTransitionEnd: function() {
        $(this.slides).find('.slides-caption').removeClass('animated fadeInUp')
        $(this.slides[this.activeIndex]).find('.slides-caption').addClass('animated fadeInUp')
      },
    },
  })

  /**
   * Resize events
   */

  $(window).resize(function() {
    if($('#wrap.fullpage-layout').length > 0)
      $.fn.fullpage.reBuild();
  })

  $(window).resize();
})
